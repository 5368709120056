import { set, uniqBy } from 'lodash';
import moment from 'moment';

import config from '../config';
import hotKeys from '../constants/hotKeys';

export const getRoundedNumber = (number) => (Number.isInteger(number) ? number : parseFloat(parseFloat(number).toFixed(2)));

export const adminUrl = () => {
  switch (config.application) {
    case 'bright-prod':
      return 'https://admin.learnwithbright.com';
    case 'bright-dev':
      return process.env.REACT_APP_ADMIN_URL || 'https://admin-dev.learnwithbright.com';
    case 'bright-dev-v2':
      return 'https://admin-dev-v2.learnwithbright.com';
    case 'bright-preprod':
      return 'https://admin-preprod.learnwithbright.com';
    case 'academy-prod':
      return 'https://admin.learnwithbright.academy';
    case 'academy-preprod':
      return 'https://admin-preprod.learnwithbright.academy';
    case 'academy-local':
      return 'http://localhost:3002';
    case 'bright-local':
      return 'http://localhost:3002';
    default:
      return 'https://admin.learnwithbright.com';
  }
};

export const websocketUrl = () => {
  if (config.application === 'bright-local') {
    return 'ws://localhost:5200';
  }

  return `wss://${window.location.host}/bright/api`;
};

export const backendUrl = () => {
  switch (config.application) {
    case 'academy-local':
      return 'http://localhost:5200';
    case 'bright-local':
      return 'http://localhost:5200';
    default:
      // use blank so we use the current domain on the browser
      return '';
  }
};

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const removeCollissions = (locations) => {
  // take one item among the array
  // check if the range of this particular item is in collission with another item in the array
  // if there is a collission, check which one has the larger length.
  // The smaller one will be discarded.
  const updatedLocations = [...locations];
  for (let i = 0; i < updatedLocations.length; i++) {
    const s1 = updatedLocations[i].offset;
    const s1Length = updatedLocations[i].length;
    const e1 = updatedLocations[i].offset + s1Length;
    for (let j = i + 1; j < updatedLocations.length; j++) {
      const s2 = updatedLocations[j].offset;
      const s2Length = updatedLocations[j].length;
      const e2 = updatedLocations[j].offset + s2Length;
      if (s2 >= s1 && s2 <= e1) {
        // s2 in between the selected location
        if (s1Length >= s2Length) {
          // s2 can be removed
          set(updatedLocations, `[${j}].invalid`, true);
        } else {
          // s1 can be removed
          set(updatedLocations, `[${i}].invalid`, true);
          break;
        }
      } else if (e2 >= s1 && e2 <= e1) {
        if (s1Length >= s2Length) {
          // s2 can be removed
          set(updatedLocations, `[${j}].invalid`, true);
        } else {
          // s1 can be removed
          set(updatedLocations, `[${i}].invalid`, true);
          break;
        }
      } else if (s2 <= e1 && e2 >= s1) {
        if (s1Length >= s2Length) {
          // s2 can be removed
          set(updatedLocations, `[${j}].invalid`, true);
        } else {
          // s1 can be removed
          set(updatedLocations, `[${i}].invalid`, true);
          break;
        }
      }
    }
  }

  return updatedLocations.filter((item) => !item.invalid);
};

export const generateSpeechToTextHighlight = (text, locations = [], isCorrected = false) => {
  const sortedLocations = locations.sort((a, b) => (a.offset < b.offset ? 1 : -1));
  let textWithHighlight = text;
  let validLocations = removeCollissions(sortedLocations);
  validLocations = uniqBy(validLocations, 'offset');

  validLocations.forEach((location) => {
    const start = location.offset;
    const end = location.offset + location.length;

    textWithHighlight = `${textWithHighlight.substring(0, start)}
      <mark style="
        background-color: ${location.color};
        padding: 2px;
      ">
      ${text.substring(start, end)}
      </mark>
      ${textWithHighlight.substring(end)}`;
  });

  return isCorrected ? text : textWithHighlight;
};

export const adaptUrlDomainAccordingToEnvironment = (url = '') => {
  let transformedUrl = '';
  switch (config.application) {
    case 'bright-prod':
      transformedUrl = url.replace(
        /(http|https):\/\/(.*)\.learnwithbright\.com/,
        'https://admin.learnwithbright.com',
      );
      break;
    case 'bright-preprod':
      transformedUrl = url.replace(
        /(http|https):\/\/(.*)\.learnwithbright\.com/,
        'https://admin-preprod.learnwithbright.com',
      );
      break;
    case 'bright-dev':
      transformedUrl = url.replace(
        /(http|https):\/\/(.*)\.learnwithbright\.com/,
        process.env.REACT_APP_ADMIN_URL || 'https://admin-dev.learnwithbright.com',
      );
      break;
    case 'bright-dev-v2':
      transformedUrl = url.replace(
        /(http|https):\/\/(.*)\.learnwithbright\.com/,
        'https://admin-dev-v2.learnwithbright.com',
      );
      break;
    case 'academy-prod':
      transformedUrl = url.replace(
        /(http|https):\/\/(.*)\.learnwithbright\.com/,
        'https://admin.learnwithbright.academy',
      );
      break;
    case 'academy-preprod':
      transformedUrl = url.replace(
        /(http|https):\/\/(.*)\.learnwithbright\.com/,
        'https://admin-preprod.learnwithbright.academy',
      );
      break;
    case 'bright-local': {
      const isPortIncluded = url.includes(':5200');
      transformedUrl = url.replace(
        /(http|https):\/\/(.*)\.learnwithbright\.com/,
        `http://localdev.learnwithbright.com${isPortIncluded ? '' : ':5200'}`,
      );
      break;
    }
    default:
      transformedUrl = url;
      break;
  }
  return transformedUrl;
};

export const getHotkeyCombinations = ({ hotKeyCombo, hotKeyMain }) => {
  let result = '';
  if (hotKeyMain !== undefined) {
    if (hotKeyCombo !== undefined) {
      const hotkeyOptions = hotKeys[hotKeyCombo] || [];
      result = hotkeyOptions.flatMap((combo) => {
        const combinations = [`${combo}+${hotKeyMain}`];
        if (/^[a-z]$/.test(hotKeyMain)) {
          combinations.push(`${combo}+${hotKeyMain.toUpperCase()}`);
        }
        return combinations;
      });
    } else {
      result = [`${hotKeyMain}`];
      if (/^[a-z]$/.test(hotKeyMain)) {
        result.push(hotKeyMain.toUpperCase());
      }
    }
  }
  return result;
};

export const convertSecondsToMMSS = (seconds) => {
  if (!seconds) {
    return null;
  }

  const duration = moment.duration(Math.ceil(seconds), 'seconds');
  const minutes = duration.minutes();
  const secondsRemaining = duration.seconds();

  const formattedHours = duration.hours() > 0 ? `${duration.hours()}:` : '';
  const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
  const formattedSeconds = secondsRemaining < 10 ? `0${secondsRemaining}` : secondsRemaining;

  return `${formattedHours}${formattedMinutes}:${formattedSeconds}`;
};

export const convertSecondsToHours = (seconds) => {
  if (!seconds) {
    return null;
  }
  const duration = moment.duration(Math.ceil(seconds), 'seconds');
  const hours = duration.hours();
  // Add Decimal to Hours (0.1 => 6 minutes)
  const hourFraction = Number(((duration.minutes() / 6) * 0.1).toFixed(1));
  return hours + hourFraction;
};

export const setOpacityForRgbColor = (rgbColor, opacity) => {
  // Check if the color is in RGB or RGBA format using regex
  const rgbRegex = /^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/;
  const rgbaRegex = /^rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d(\.\d+)?)\)$/;

  if (rgbRegex.test(rgbColor)) {
    const [, r, g, b] = rgbColor.match(rgbRegex);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  if (rgbaRegex.test(rgbColor)) {
    const [, r, g, b] = rgbColor.match(rgbaRegex);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
  }

  return rgbColor; // Return the original color if it's not in RGB or RGBA format
};
