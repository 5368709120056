/* eslint-disable class-methods-use-this */
/* eslint-disable import/no-cycle */

import axios from 'axios';
import config from '../common/config';
import authStore from './stores/AuthStore';
import UserStore from './stores/UserStore';

const GET = 'get';
const PUT = 'put';
const POST = 'post';
const DELETE = 'delete';

let generalController = new AbortController();

const initApi = () => {
  const api = axios.create();
  api.defaults.baseURL = config.apiURL;

  return api;
};

const initVimeoApi = () => {
  const api = axios.create();
  api.defaults.baseURL = config.vimeoURL;

  return api;
};

const axiosApi = initApi();
const axiosVimeoApi = initVimeoApi();

const redirectOn401 = (error) => {
  const LOGIN_PATHS = ['/trugreen', '/genpact', '/login', '/ttec'];

  const currentURL = window.location.href;
  const alreadyInLogin = LOGIN_PATHS.some((path) => currentURL.includes(path));
  if (alreadyInLogin) return;

  if (error.response?.data?.ssoUrl) {
    window.location.href = error.response.data.ssoUrl;
  } else if (UserStore?.user?.customerUrl && UserStore?.user?.customer_id !== 16) {
    window.location.href = UserStore.user.customerUrl;
  } else if (UserStore?.user?.customer_id === 16) {
    window.location.href = '/trugreen';
  } else if (UserStore?.user?.customer_id === 32) {
    window.location.href = '/genpact';
  } else if (UserStore?.user?.customer_id === 38 || UserStore?.user?.customer_id === 50) {
    window.location.href = '/ttec';
  } else {
    window.location.href = '/login';
  }
};

const getPaginationAndSortingQueries = (queryParams) => {
  const {
    pageIndex,
    pageSize,
    sortKey,
    isAscOrder,
  } = queryParams;
  const queries = [];

  if (pageIndex >= 0) {
    queries.push(`pageIndex=${pageIndex}`);
  }

  if (pageSize >= 0) {
    queries.push(`pageSize=${pageSize}`);
  }

  if (sortKey) {
    queries.push(`sortKey=${sortKey}`);
  }

  if (isAscOrder) {
    queries.push(`isAscOrder=${isAscOrder}`);
  }

  return queries;
};

const execute = async (verb, url, body, options) => {
  try {
    const configParams = {
      url,
      method: verb,
      data: body,
      withCredentials: true,
      signal: generalController.signal,
      ...options,
      headers: {
        ...options?.headers,
        'x-current-url': window.location.href,
      },
    };
    const { status, data } = await axiosApi.request(configParams);

    //  reset auth session expiry on successful api return
    authStore.resetExpiryDate();

    return {
      status,
      data,
    };
  } catch (error) {
    if (!error.response) {
      return {
        status: 'aborted',
        data: null,
      };
    }

    const { status } = error.response;
    // handle 401s in the store, payload may contain relevant redirect info

    if (status === 401) {
      redirectOn401(error);
    }

    return {
      status,
      data: error.response?.data || null,
      response: error.response,
    };
  }
};

class API {
  cancelRequests() {
    generalController.abort();
    generalController = new AbortController();
  }

  async login(username) {
    return execute(POST, '/login', { username, password: 'password' });
  }

  getUserProfile() {
    return execute(GET, '/users/me?application=bright');
  }

  setCustomerInstance(customerId) {
    return execute(POST, '/users/auth/customer', { customerId });
  }

  finishOnboarding() {
    return execute(PUT, '/users/onboarding?application=bright');
  }

  getUsers() {
    return execute(GET, '/users?application=bright');
  }

  getUserAvatars() {
    return execute(GET, '/users/avatars?application=bright');
  }

  getTeam() {
    return execute(GET, '/users/team?application=bright');
  }

  async getInvitation(invitationId) {
    return execute(GET, `/invitations/${invitationId}`);
  }

  async getMoments() {
    return execute(GET, '/moments?active=true&withTags=true');
  }

  async getMoment(momentId) {
    return execute(GET, `/moments/${momentId}?withTags=true&filteredQuestions=true`);
  }

  async getMomentWithFilteredQuestions(momentId) {
    return execute(GET, `/moments/filteredQuestions?ids=${momentId}`);
  }

  async getMomentScreens(momentId) {
    return execute(GET, `/moments/${momentId}/screens`);
  }

  createRoom(momentId) {
    return execute(POST, `/moments/${momentId}/room`, {});
  }

  completeRoom(momentId, roomId, status) {
    return execute(PUT, `/moments/${momentId}/room`, { roomId, status });
  }

  createComposition(momentId, roomId) {
    return execute(POST, `/moments/${momentId}/room/composition`, { roomId });
  }

  getComposition(momentId, compositionId) {
    return execute(GET, `/moments/${momentId}/room/composition/${compositionId}`);
  }

  getCompositionMedia(momentId, compositionId) {
    return execute(GET, `/moments/${momentId}/room/composition/${compositionId}/media`);
  }

  async getMomentActivity(momentId, userId) {
    return execute(GET, `/user-progress?application=bright&momentId=${momentId}${userId ? `&userId=${userId}` : ''}`);
  }

  async getUserActivity(userId) {
    return execute(GET, `/user-progress?application=bright&userId=${userId}`);
  }

  async createMomentActivity(activity) {
    return execute(POST, '/user-progress?application=bright', activity);
  }

  async updateCertificationProgress(reviewerId, userId, certificationId, passed, grade) {
    return execute(PUT, '/user-progress?application=bright', {
      userId,
      certificationId,
      extra: {
        reviewerId,
        passed,
        grade,
      },
    });
  }

  async markItemsinKudosAsViewed(transactionIds) {
    return execute(POST, '/user-progress/kudos/mark-view', {
      transactionIds,
    });
  }

  async markItemsInCertificationsAsViewed(transactionIds) {
    return execute(POST, '/user-progress/certifications/mark-view', {
      transactionIds,
    });
  }

  async getSimulations() {
    return execute(GET, '/simulations');
  }

  async getSimulation(simulationId) {
    return execute(GET, `/simulations/${simulationId}?application=bright`);
  }

  async getInboxNotificationData() {
    return execute(GET, '/notifications/me/notification-overview');
  }

  async getInboxFilterOptions(queryParams = []) {
    const queryString = `?${['application=bright', ...queryParams].join('&')}`;

    return execute(GET, `/notifications/me/filter-options${queryString}`);
  }

  async getInboxRefreshCheck(body) {
    return execute(POST, '/notifications/me/refresh-cue', body);
  }

  async getInboxListing(body) {
    return execute(POST, '/notifications/me/listing', body);
  }

  async getInbox(scope) {
    return execute(GET, `/notifications/me?application=bright&scope=${scope}`);
  }

  async markAllRead(type, scope) {
    return execute(GET, `/notifications/markAllRead?application=bright&type=${type}&scope=${scope}`);
  }

  async updateInboxItem(item) {
    return execute(PUT, `/notifications/${item.id}?application=bright`, item);
  }

  async getUserProgress(progressId) {
    if (progressId) {
      return execute(GET, `/user-progress/${progressId}`);
    }
  }

  async getUserProgressByTransaction(txId, options = {}) {
    return execute(GET, `/user-progress/transaction/${txId}`, '', {
      params: {
        includeAI: (options.includeAI || false),
      },
    });
  }

  async updateUserProgress(userProgresses, createNotifcation = true) {
    let hasCreatedNotification = true;

    return Promise.all(userProgresses.map((userProgress) => {
      if (createNotifcation && hasCreatedNotification) {
        hasCreatedNotification = false;
        return execute(PUT, `/user-progress/${userProgress.id}?createNotification=true&application=bright`, userProgress);
      }
      return execute(PUT, `/user-progress/${userProgress.id}?createNotification=false&application=bright`, userProgress);
    }));
  }

  async updateTranscription(userProgressId, newTranscription) {
    return execute(PUT, `/user-progress/${userProgressId}/transcription`, { newTranscription });
  }

  async refreshAIRules(momentId) {
    return execute(GET, `/user-progress/refresh-ai-rules?momentId=${momentId}`);
  }

  async getMomentInformationByUserProgresses(progressIds) {
    return execute(POST, 'user-progress-moments', progressIds);
  }

  async logout(url, method) {
    return execute(GET, '/logout');
  }

  async getToken(twilioName) {
    return execute(GET, `/token?identity=${twilioName}`);
  }

  async uploadText(momentId, text, options, moment) {
    return execute(POST, '/user-progress/text', { text, moment },
      {
        params: {
          ...options,
          momentId,
        },
      });
  }

  async getOrCreateUserProgressFromScorm({ momentId, scormFileId }) {
    return execute(POST, '/user-progress/scorm', { momentId, scormFileId });
  }

  async updateScormProgress(progressId, scormData) {
    return execute(PUT, `/user-progress/${progressId}`, scormData);
  }

  async uploadVideo(momentId, sid) {
    return execute(POST, `/user-progress/video?&momentId=${momentId}`, { sid });
  }

  async uploadRecording(momentId, recording, type, options) {
    return execute(POST, '/user-progress/recording', recording, {
      'Content-Type': 'multipart/form-data',
      params: {
        ...options,
        type,
        momentId,
      },
    });
  }

  async getPreSignedURL({ momentId, type }) {
    return execute(POST, '/user-progress/getPreSignedURL', {
      momentId, type,
    });
  }

  async batchAudioProcessing({ recording, model, keywords, replaceTerms }) {
    return execute(POST, '/user-progress/batchAudioProcessing', recording, {
      'Content-Type': 'multipart/form-data',
      params: {
        model,
        keywords,
        replaceTerms,
      },
    });
  }

  async generateAudioForText({ text, voice }) {
    return execute(POST, `/user-progress/generateAudioForText?model=${voice}`, { text }, { responseType: 'arraybuffer' });
  }

  async uploadDocument(momentId, documentUrl) {
    return execute(POST, `/user-progress/document?&momentId=${momentId}`, { documentUrl });
  }

  async getCertifications() {
    return execute(GET, '/certifications?withTags=true');
  }

  async getCertification(certificationId) {
    return execute(GET, `/certifications/${certificationId}?application=bright&withTags=true`);
  }

  async getCertificationActivity(userId) {
    return execute(GET, '/user-progress?application=bright&type=certification');
  }

  async getCertificationScreens(certificationId) {
    return execute(GET, `/certifications/${certificationId}/screens`);
  }

  async getScreen(screenId) {
    return execute(GET, `/screens/${screenId}`);
  }

  async getScreenGroup(screenGroupId) {
    return execute(GET, `/screen-groups/${screenGroupId}`);
  }

  async getScreens() {
    return execute(GET, '/screens');
  }

  async getResources() {
    return execute(GET, '/resource');
  }

  async uploadFiles(uploadData) {
    return execute(POST, '/files', uploadData);
  }

  async uploadRichTextResource(uploadData) {
    return execute(POST, '/files/rich-text-resource', uploadData);
  }

  async getLoginMechanism(username) {
    return execute(POST, '/login/mechanism', { username });
  }

  async getLoginMechanismTrugreen(username) {
    return execute(POST, '/login/mechanismTrugreen', { username, password: 'password' });
  }

  // Teams info
  async getAllTeams() {
    return execute(GET, '/teams');
  }

  async getTierOneTeams() {
    return execute(GET, '/v2/teams/listing/tierOne');
  }

  async getUsersByTeam(teamId) {
    return execute(GET, `/teams/${teamId}/users`);
  }

  // Reports
  async getAllTeamsReportsOverviewData(teamId, assignmentPath) {
    if (teamId) return execute(GET, `/reports/allteams/overview?teamId=${teamId}&assignmentPath=${assignmentPath}`);
    return execute(GET, `/reports/allteams/overview?assignmentPath=${assignmentPath}`);
  }

  async getAllTeamsReportsListing(queryParams) {
    const paginationAndSoringQueries = getPaginationAndSortingQueries(queryParams);
    const queries = [...paginationAndSoringQueries];
    let queryString = '';

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }

    if (queryParams.teamId) {
      queryString += `&teamId=${queryParams.teamId}`;
    }

    queryString += `&assignmentPath=${queryParams.assignmentPath}`;
    queryString += `&listingGroup=${queryParams.listingGroup}`;

    return execute(GET, `/reports/allteams/listing${queryString}`);
  }

  async getIndividualTeamReportOverviewData({
    teamId,
    pathId,
    assignmentPath,
    listingGroup, // path or team
  }) {
    if (listingGroup === 'path') {
      return execute(GET, `/reports/team/${teamId}/path/${pathId}/overview`, {}, {
        params: {
          assignmentPath,
          listingGroup,
        },
      });
    }
    return execute(GET, `/reports/team/${teamId}/overview`, {}, {
      params: {
        assignmentPath,
        listingGroup,
      },
    });
  }

  async getIndividualTeamReportListing(teamId, pathId, queryParams) {
    const paginationAndSoringQueries = getPaginationAndSortingQueries(queryParams);
    const queries = [...paginationAndSoringQueries];
    let queryString = '';

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }
    queryString += `&assignmentPath=${queryParams.assignmentPath}`;
    queryString += `&listingGroup=${queryParams.listingGroup}`;

    if (queryParams.listingGroup === 'path') {
      return execute(GET, `/reports/team/${teamId}/path/${pathId}/listing${queryString}`);
    }
    return execute(GET, `/reports/team/${teamId}/listing${queryString}`);
  }

  async getTraineeTeamReportOverviewData(teamId, traineeId, assignmentPath, pathId) {
    return execute(GET, `/reports/team/${teamId}/trainee/${traineeId}/overview`, {}, {
      params: {
        assignmentPath,
        pathId,
      },
    });
  }

  async getTeamShortcutPath(teamId) {
    return execute(GET, `/reports/allteams/listing-path?${teamId ? `teamId=${teamId}` : ''}`);
  }

  async getTraineeTeamReportMomentsAndCertificationsCounts(
    teamId,
    traineeId,
    queryParams) {
    const queries = [];
    let queryString = '';
    queries.push(`assignmentPath=${queryParams.assignmentPath}`);

    if (queryParams.tagCategoryId) {
      queries.push(`tagCategoryId=${queryParams.tagCategoryId}`);
    }

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }

    return execute(GET, `/reports/team/${teamId}/trainee/${traineeId}/overview/counts${queryString}`);
  }

  async getTraineeTeamReportMomentsListing(teamId, traineeId, queryParams) {
    const paginationAndSoringQueries = getPaginationAndSortingQueries(queryParams);
    const queries = [...paginationAndSoringQueries];
    let queryString = '';
    queries.push(`assignmentPath=${queryParams.assignmentPath}`);

    if (queryParams.tagCategoryId) {
      queries.push(`tagCategoryId=${queryParams.tagCategoryId}`);
    }

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }

    return execute(GET, `/reports/team/${teamId}/trainee/${traineeId}/listing/moments${queryString}`);
  }

  async getTraineeTeamReportCertificationListing(teamId, traineeId, queryParams) {
    const paginationAndSoringQueries = getPaginationAndSortingQueries(queryParams);
    const queries = [...paginationAndSoringQueries];
    let queryString = '';

    if (queryParams.tagCategoryId) {
      queries.push(`tagCategoryId=${queryParams.tagCategoryId}`);
    }

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }

    return execute(GET, `/reports/team/${teamId}/trainee/${traineeId}/listing/certifications${queryString}`);
  }

  async getTierBasedListing(queryParams) {
    const paginationAndSoringQueries = getPaginationAndSortingQueries(queryParams);
    const queries = [...paginationAndSoringQueries];
    let queryString = '';

    if (queryParams.searchText) {
      queries.push(`searchText=${queryParams.searchText}`);
    }
    if (queryParams.teamsId) {
      queries.push(`id=${queryParams.teamsId}`);
    }
    if (queryParams.id) {
      queries.push(`parentId=${queryParams.id}`);
    }
    if (queryParams.tierId) {
      queries.push(`tier=${queryParams.tierId + 1}`);
    }

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }

    return execute(GET, `/v2/teams/tierBasedListing/${queryString}`);
  }

  async getTeamsByFilter({ teamIds }) {
    return execute(POST, '/v2/teams/search', { teamIds });
  }

  async getActivityReportOverviewData() {
    return execute(GET, '/reports/activity-report/overview');
  }

  async getActivityReportData(filterValues) {
    const { dateRange } = filterValues;
    const queries = [];
    let queryString = '';

    if (dateRange.endDate >= dateRange.startDate) {
      queries.push(`startDate=${dateRange.startDate.toISOString()}`);
      queries.push(`endDate=${dateRange.endDate.toISOString()}`);
    }

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }

    return execute(GET, `/reports/activity-report/activity${queryString}`);
  }

  async getMomentReportMomentChoices() {
    return execute(GET, '/reports/moment/moment-choices');
  }

  async getMomentReportOverviewData(momentId) {
    return execute(GET, `/reports/moment/${momentId}/overview`);
  }

  async getMomentReportStatsData(momentId, queryParams) {
    const queries = [];
    let queryString = '';

    if (queryParams && queryParams.teamsId && queryParams.teamsId.length) {
      queries.push(`teamId=${queryParams.teamsId.join(',')}`);
    }

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }

    return execute(GET, `/reports/moment/${momentId}/stats${queryString}`);
  }

  async getMomentReportMemberListingData(momentId, queryParams) {
    const paginationAndSoringQueries = getPaginationAndSortingQueries(queryParams);
    const queries = [...paginationAndSoringQueries];
    let queryString = '';

    if (queryParams.teamsId && queryParams.teamsId.length) {
      queries.push(`teamId=${queryParams.teamsId.join(',')}`);
    }

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }

    return execute(GET, `/reports/moment/${momentId}/member-listing${queryString}`);
  }

  async getTeamProgressExcel(teamId, assignmentPath, start = false, key = null) {
    return execute(GET, '/reports/allteams/sheet', null, {
      params: {
        teamId,
        assignmentPath,
        start,
        key,
      },
    });
  }

  // AI report
  async getAITrainingOverviewData(filterValues) {
    const { path, status } = filterValues;
    let queryString = '?';
    if (path && path.length) {
      queryString += `path=${path.join(',')}`;
    }
    if (status) {
      if (!path || path.length === 0) {
        queryString += `status=${status}`;
      } else {
        queryString += `&status=${status}`;
      }
    }
    return execute(GET, `/reports/ai-training-report/overview${queryString}`);
  }

  async getAITrainingListingData(queryParams) {
    const paginationAndSoringQueries = getPaginationAndSortingQueries(queryParams);
    const queries = [...paginationAndSoringQueries];
    let queryString = '?';

    const { path, status } = queryParams;
    if (path && path.length) {
      queries.push(`path=${path.join(',')}`);
    }
    if (status) {
      queries.push(`status=${status}`);
    }

    if (queries.length) {
      queryString += `${queries.join('&')}`;
    }

    return execute(GET, `/reports/ai-training-report/listing${queryString}`);
  }

  async updateMoment({ momentId, momentData }) {
    return execute(PUT, `/moments/${momentId}`, momentData);
  }

  async uploadUserAvatar(avatarData) {
    return execute(POST, '/user-management/avatar', avatarData);
  }

  async updateUser(userData) {
    return execute(PUT, '/user-management/profile', userData);
  }

  async getUserAssignment() {
    return execute(GET, '/user-management/assignment');
  }

  async getAssignmentList() {
    return execute(GET, '/assignments-coach/listing');
  }

  async getAssignmentByIdData(
    assignmentId,
    teamsId,
    startDate,
    endDate,
    assignmentPath,
  ) {
    let queryString = '';
    const queries = [];
    queries.push(`assignmentPath=${assignmentPath}`);

    if (startDate && endDate && endDate >= startDate) {
      queries.push(`startDate=${startDate.toISOString()}`);
      queries.push(`endDate=${endDate.toISOString()}`);
    }

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }
    return execute(GET, `/assignments-coach/${assignmentId}/${teamsId.join(',')}${queryString}`);
  }

  async getAISubmissions(ids) {
    let queryString = '';
    if (ids.length) queryString = `${ids.join(',')}`;
    return execute(GET, `/ai/submissions?ids=${queryString}`);
  }

  // welcome-placemat
  async getWelcomePlacemat() {
    return execute(GET, '/welcome-placemat/');
  }

  // Coach report
  async getCoachReportFilterOptions() {
    return execute(GET, '/reports/coach-report/filter-options');
  }

  async getCoachesReportOverviewData(teamId) {
    let queryString = '';

    if (teamId) {
      queryString = `?teamId=${teamId}`;
    }
    return execute(GET, `/reports/allcoaches/overview${queryString}`);
  }

  async getAllCoachesListing(queryParams, teamId, abortController = null) {
    const paginationAndSoringQueries = getPaginationAndSortingQueries(queryParams);
    const queries = [...paginationAndSoringQueries];
    let queryString = '';

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
      if (teamId) {
        queryString += `&teamId=${teamId}`;
      }
    } else if (teamId) {
      queryString = `?teamId=${teamId}`;
    }

    return execute(GET, `/reports/allcoaches/listing${queryString}`, {}, { signal: abortController?.signal });
  }

  async getCoachReportOverviewData(coachId, filterValues) {
    const {
      momentId, teamId, startDate, endDate,
    } = filterValues;

    let queryString = '?';

    if (momentId) {
      queryString += `momentId=${momentId}&`;
    }
    if (teamId) {
      queryString += `teamId=${teamId}&`;
    }
    if (startDate && endDate) {
      queryString += `startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
    }
    return execute(GET, `/reports/coach/${coachId}/overview${queryString}`);
  }

  async getCoachFilters(coachId) {
    return execute(GET, `/reports/coach/${coachId}/filters`);
  }

  async getIndividualCoachData(queryParams, coachId, filterValues) {
    const paginationAndSoringQueries = getPaginationAndSortingQueries(queryParams);
    const queries = [...paginationAndSoringQueries];
    let queryString = '';

    const {
      teamId, momentId, startDate, endDate,
    } = filterValues;

    if (queries.length) {
      queryString = `?${queries.join('&')}`;
    }
    if (teamId) {
      queryString += `&teamId=${teamId}`;
    }
    if (momentId) {
      queryString += `&momentId=${momentId}`;
    }
    if (startDate && endDate) {
      queryString += `&startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`;
    }
    return execute(GET, `/reports/coach/${coachId}/listing${queryString}`);
  }

  async getOutsandingReportData(teamId, assignmentPath) {
    return execute(
      GET,
      '/reports/outstanding/overview',
      '',
      {
        params: {
          teamId,
          assignmentPath,
        },
      },
    );
  }

  // Inbox
  async uploadMediaRecording(momentId, userId, customerId, file, type, setMediaUploadProgress) {
    return execute(
      POST,
      `/user-progress-fileupload/?type=${type}&momentId=${momentId}&userId=${userId}&customerId=${customerId}`,
      file,
      {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setMediaUploadProgress(percentCompleted);
        },
      },
    );
  }

  async uploadFile(momentId, userId, customerId, file, type, fileName, extension, setFileUploadProgress) {
    return execute(
      POST,
      `/user-progress-fileupload/?type=${type}&fileName=${fileName}&extension=${extension}&momentId=${momentId}&userId=${userId}&customerId=${customerId}`,
      file,
      {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setFileUploadProgress(percentCompleted);
        },
      },
    );
  }

  // SKILLS + AI
  async getAISubmissionsCount(momentId) {
    return execute(GET, `/ai/count/${momentId}`);
  }

  async getHumanCoachByCustomer() {
    return execute(GET, '/skills-ai/human-coach');
  }

  async getAWSTemporaryToken() {
    return execute(GET, '/aws');
  }

  // LOG
  async createLog(log) {
    return execute(POST, '/logs', log);
  }

  async authenticateToAdmin(token) {
    return execute(POST, '/auth/admin', {}, { headers: { Authorization: `Bearer ${token}` } });
  }

  async authenticateToAcademy(token) {
    return execute(POST, '/auth/academy', {}, { headers: { Authorization: `Bearer ${token}` } });
  }

  async getAdminAccessToken() {
    return execute(GET, '/auth/admin-access-token');
  }

  // AI CONVERSATION
  async getPublishedAIConversation({ contentAreaId, isCoachOrAdmin, userId }) {
    let query = '';
    if (isCoachOrAdmin && userId) {
      query += `isCoachOrAdmin=${isCoachOrAdmin}&&userId=${userId}`;
    }

    return execute(GET, `/frontend/ai-conversation/published/${contentAreaId}${query?.length ? `?${query}` : ''}`);
  }

  async getDraftAIConversation(contentAreaId) {
    return execute(GET, `/frontend/ai-conversation/draft/${contentAreaId}`);
  }

  async startAttempt({ contentAreaId, versionId, type, canvasId, generativeConversationId }) {
    return execute(GET, `/frontend/ai-conversation/start-attempt/${contentAreaId}/${versionId}`, '', {
      params: {
        type,
        canvasId,
        generativeConversationId,
      },
    });
  }

  async sendMessage(text, {
    activeNode,
    attemptId,
    contentAreaId,
    versionId,
    responseTime,
    duration,
    lifelinesUsed,
    systemErrors,
  }) {
    return execute(POST, `/frontend/ai-conversation/send-message/${attemptId}`, {
      text,
      activeNode,
      contentAreaId,
      versionId,
      responseTime,
      duration,
      lifelinesUsed,
      systemErrors,
    });
  }

  async testSubmission(payload) {
    return execute(POST, '/admin/ai-conversation/testSubmission', payload);
  }

  async getDebriefInfo({ contentAreaId, attemptId, isCoachOrAdmin, userId }) {
    let query = '';
    if (isCoachOrAdmin && userId) {
      query += `isCoachOrAdmin=${isCoachOrAdmin}&&userId=${userId}`;
    }

    return execute(GET, `/frontend/ai-conversation/${contentAreaId}/attempt/${attemptId}${query?.length ? `?${query}` : ''}`);
  }

  async completeAttempt(
    payload,
    { attemptId },
  ) {
    return execute(POST, `/frontend/ai-conversation/complete-attempt/${attemptId}`, payload);
  }

  async createLLMDialog(payload) {
    return execute(POST, '/frontend/ai-conversation/llm-dialog', payload);
  }

  async messageLLMDialog(conversationId, payload) {
    return execute(POST, `/frontend/ai-conversation/llm-dialog/${conversationId}`, payload);
  }

  async getAllUserAttempts(userId) {
    return execute(GET, `/frontend/ai-conversation/user-attempts?userId=${userId}`);
  }

  async getConversationsByUsers(userIds) {
    return execute(POST, '/frontend/ai-conversation/users/conversations', { userIds });
  }

  async getSkillsReport({
    conversationId, userIds,
  }) {
    return execute(POST, `/frontend/ai-conversation/skills-report/${conversationId}`, {
      userIds,
    });
  }

  async getExploreAssignments(teamIds = [], searchText = '') {
    if (config.isAcademy) return { data: [] };

    return execute(GET, `/assignments/explore?taggedTeamIds=${teamIds.join(',')}&searchText=${searchText}`);
  }

  async addAssignmentToExplore(assignmentId) {
    return execute(POST, `/assignments/explore/${assignmentId}`);
  }

  async removeAssignmentFromExplore(assignmentId) {
    return execute(DELETE, `/assignments/explore/${assignmentId}`);
  }

  async getBadgesForUser() {
    return execute(GET, '/badge/listing/current-user');
  }

  async setBadgeAsViewed(badgeId) {
    return execute(POST, `/badge/${badgeId}/view`);
  }

  async getVimeoThumbnail(videoId) {
    const configParams = {
      url: `${videoId.pathname}&width=1500`,
      method: GET,
    };

    return axiosVimeoApi.request(configParams);
  }

  async getGenAIConversationsList() {
    return execute(GET, '/gen-ai/get-simulation-configs');
  }

  async startGenAIConversation(body) {
    return execute(POST, '/gen-ai/create-conversation', body);
  }

  async sendGenAIMessage(body) {
    return fetch(`${config.apiURL}/gen-ai/send-message`, {
      method: POST,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'text/event-stream',
      },
      body: JSON.stringify(body),
      credentials: 'include',
    });
  }

  async finishGenAIConversation(body) {
    return execute(POST, '/gen-ai/finish-conversation', body);
  }

  async addGenAIInteraction(body) {
    return execute(POST, '/frontend/gen-ai-interaction', body);
  }

  async getGenAIInteraction(userAttemptId) {
    return execute(GET, `/frontend/gen-ai-interaction/user-attempt/${userAttemptId}`);
  }

  async generateGenAICoachFeedback({ conversation_id, attemptId }) {
    return execute(POST, '/gen-ai/generate-coach-feedback', { attemptId, conversation_id });
  }

  async pollCoachFeedback({ attemptId }) {
    return execute(GET, `/gen-ai/poll-coach-feedback/${attemptId}`);
  }

  async getHeyGenApiKey() {
    return execute(GET, '/hey-gen/api-key');
  }

  async getLesson(lessonId) {
    return execute(GET, `/lesson/${lessonId}`);
  }

  async getModuleProgress(lessonId) {
    return execute(GET, `/lesson/${lessonId}/progress`);
  }

  async createModuleProgress(userProgressId, lessonId, moduleId, questionChoices = {}) {
    return execute(POST, `/lesson/${lessonId}/module/${moduleId}/progress`, { userProgressId, questionChoices });
  }

  async createUserProgressFromLesson(momentId, lessonId) {
    return execute(POST, `/lesson/${lessonId}/progress`, { momentId });
  }
}

export default new API();
